// @import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
// @import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
// @import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
// @import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
// @import '../../node_modules/@syncfusion/ej2-lists/styles/material.css';
// @import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
// @import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
// @import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
// @import '../../node_modules/@syncfusion/ej2-spreadsheet/styles/material.css';
// @import '../../node_modules/@syncfusion/ej2-grids/styles/material.css';
.mat-mdc-snack-bar-container {
    --mat-mdc-snack-bar-button-color: #ffffff !important;
    --mdc-snackbar-supporting-text-color: #ffffff !important;
  
    &.app-notification-error {
      --mdc-snackbar-container-color: #f23a2f !important;
    }
  
    &.app-notification-success {
      --mdc-snackbar-container-color: #43a446 !important;
    }
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  .no-access{    
    font-size: 25px;
    font-weight: bold;
    display: flex;
    color: #0f172a;
    align-items: flex-start;
    justify-content: center;
  }
  .breadcrumb{
      font-size: 16px;
      padding: 24px;
      // .bold{
      //   font-weight: bold;
      // }
      .link{
        color: #1D67A4;
        text-decoration: none !important;
      }
  }
  .delete-button{
    background-color: #f23a2f !important;
  }
  .country-selector{
    margin-top: 3px;
  }
  .max-w-300{
    max-width: 300px !important;
  }
  .min-w-250{
    min-width: 250px !important;
  }
  .mat-sort-header-container {
    justify-content: center;
  }
  @media (max-width: 500px) {
    .no-access{
      font-size: 20px;
    }
}